import React from 'react'
import { Button } from "./UI/button.jsx"
import { useTranslation } from 'react-i18next';

export default function Hero() {
    const { t, i18n } = useTranslation();
    const openUrl = () => {
      window.open('https://app.avocatspark.com/auth/register', '_blank');
    }
  return (
    <section  className={`py-12 px-4 bg-gray-100 flex items-center h-screen ${i18n.language === 'ar' ? 'flex-row-reverse' : ''}`}>
      <div className={`w-2/5 text-center ${i18n.language === 'ar' ? 'text-align-right' : ''}`}>
        <h1 className="text-5xl font-bold mb-4">{t('hero_title')}</h1>
        <p className={`mb-8 text-xl text-gray-600 ${i18n.language === 'ar' ? 'text-align-right' : ''}`}>{t('hero_desc')}</p>
        <div className="flex justify-center space-x-4">
          <Button onClick={openUrl} className="text-white">{t('Try Now')}</Button>
          <Button variant="outline"><a href="#tarifs" className="text-gray-600 hover:text-gray-900">{t('See More')}</a></Button>
        </div>
      </div>
      <div className="w-3/5">
        <img src="hero_image.png" alt="Avocat Spark Platform" className="w-full h-auto" />
      </div>
    </section>
    )
}