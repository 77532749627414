import React from 'react'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "./UI/accordions.jsx" 
import { useTranslation } from 'react-i18next';

export default function FAQ() {
    const { t, i18n } = useTranslation();
  return (
    <section id="faq" className="py-16 px-4 bg-gray-50 w-full">
      <div className="container mx-auto w-full">
        <h2 className="text-6xl font-bold text-center mb-4 text-gray-800">{t('FAQ_title')}</h2>
        <p className="text-center text-gray-400 mb-12 max-w-6xl mx-auto">{t('FAQ_desc')}</p>
        <Accordion type="single" collapsible className="max-w-6xl mx-auto">
          <AccordionItem value="item-1" className="max-w-6xl mx-auto">
            <AccordionTrigger>{t('FAQ_acc_q_1')}</AccordionTrigger>
            <AccordionContent>
                {t('FAQ_acc_a_1')}
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-2" className="max-w-6xl mx-auto">
            <AccordionTrigger>{t('FAQ_acc_q_2')}</AccordionTrigger>
            <AccordionContent>
                {t('FAQ_acc_a_2')}
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-3" className="max-w-6xl mx-auto">
            <AccordionTrigger>{t('FAQ_acc_q_3')}</AccordionTrigger>
            <AccordionContent>
                {t('FAQ_acc_a_3')}
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-4" className="max-w-6xl mx-auto">
            <AccordionTrigger>{t('FAQ_acc_q_4')}</AccordionTrigger>
            <AccordionContent>
                {t('FAQ_acc_a_4')}
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-5" className="max-w-6xl mx-auto">
            <AccordionTrigger>{t('FAQ_acc_q_5')}</AccordionTrigger>
            <AccordionContent>
                {t('FAQ_acc_a_5')}
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-6" className="max-w-6xl mx-auto">
            <AccordionTrigger>{t('FAQ_acc_q_6')}</AccordionTrigger>
            <AccordionContent>
                {t('FAQ_acc_a_6')}
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </div>
    </section>
  )
}