import './styles/globals.css'
import './App.css';
import React from 'react'
import Header from './components/Header.jsx'
import Hero from './components/Hero.jsx'
import Features from './components/Features.jsx'
import Pricing from './components/Pricing.jsx'
import FAQ from './components/FAQ.jsx'
import Contact from './components/Contact.jsx'
// import Hero from '@/components/Hero'
// import Features from '@/components/Features'
// import Pricing from '@/components/Pricing'
// import FAQ from '@/components/FAQ'
// import Contact from '@/components/Contact'
// import Footer from '@/components/Footer'

function App() { 
  return (
    <div className="App flex flex-col min-h-screen">
      <Header />
      <main className="flex-grow">
        <Hero />
        <Features />
        <Pricing />
        <FAQ />
        <Contact />
        {/* <Pricing />
        <FAQ />
        <Contact /> */}
      </main>
      {/* <Footer /> */}
    </div>
  );
}

export default App;
