import React from 'react'
import { Button } from "./UI/button"
import { Input } from "./UI/input"
import { Textarea } from "./UI/textarea"
import { Card, CardContent } from "./UI/card"
import { PhoneIcon, MailIcon, MapPinIcon, LinkedinIcon, FacebookIcon, TwitterIcon } from "lucide-react"
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import server from '../api/server';

export default function Contact() {
  const { t, i18n } = useTranslation();
  const [form, setForm] = React.useState({
    firstName: '',
    lastName: '',
    email: '',
    phone_number: '',
    message: ''
  });
  const [errors, setErrors] = React.useState({
    firstName: '',
    lastName: '',
    email: '',
    phone_number: '',
    message: ''
  });

  const sendContactForm = async () => {
    try {
      setErrors({
        firstName: '',
        lastName: '',
        email: '',
        phone_number: '',
        message: ''
      })
      const response = await server.post('/avocat/contacts', form).then(res => setForm({
        firstName: '',
        lastName: '',
        email: '',
        phone_number: '',
        message: ''
      }))
                              .catch(error => {
                                if (error.response && error.response.status === 422) {
                                  setErrors(error.response.data.errors);
                                }
                              });
      console.log(errors.firstName);
      // Handle success response
    } catch (error) {
      console.error(error);
      // Handle error response
    }
  };
  return (
    <section id="contact" className="py-16 px-4 bg-gray-100">
      <div className="max-w-full mx-20">
        <h2 className="text-4xl font-bold text-center mb-12">{t('contact_title')}</h2>
        <div className="grid md:grid-cols-2 gap-8">
          <Card className="bg-white shadow-lg rounded-lg overflow-hidden flex justify-center items-center">
            <CardContent className="p-8">
              <h3 className="text-3xl font-bold mb-6 text-gray-800">{t('contact_info')}</h3>
              <div className="flex flex-col md:flex-row md:space-x-8">
                <div className="md:w-1/2 md:pr-8">
                  <div className="space-y-6 mb-8">
                    <div className="flex items-center group">
                      <div className="bg-amber-100 p-3 rounded-full mr-4 group-hover:bg-amber-400 transition-colors duration-300">
                        <PhoneIcon className="w-6 h-6 text-amber-700" />
                      </div>
                      <span className="text-md text-gray-700 group-hover:text-amber-600 transition-colors duration-300">+212521040975</span>
                    </div>
                    <div className="flex items-center group">
                      <div className="bg-amber-100 p-3 rounded-full mr-4 group-hover:bg-amber-400 transition-colors duration-300">
                        <MailIcon className="w-6 h-6 text-amber-700" />
                      </div>
                      <span className="text-md text-gray-700 group-hover:text-amber-700 transition-colors duration-300">contact@avocatspark.com</span>
                    </div>
                    <div className="flex items-start group">
                      <div className="bg-amber-100 p-3 rounded-full mr-4 group-hover:bg-amber-400 transition-colors duration-300">
                        <MapPinIcon className="w-6 h-6 text-amber-700" />
                      </div>
                      <span className="text-sm text-gray-700 group-hover:text-amber-700 transition-colors duration-300">597 ALMASSAR QI, Marrakesh 40150</span>
                    </div>
                  </div>
                  <div className="flex justify-around mt-12">
                    <a href="#" className="text-gray-600 hover:text-amber-700 transition-colors duration-300">
                      <LinkedinIcon className="w-8 h-8" />
                    </a>
                    <a href="#" className="text-gray-600 hover:text-amber-700 transition-colors duration-300">
                      <FacebookIcon className="w-8 h-8" />
                    </a>
                    <a href="#" className="text-gray-600 hover:text-amber-700 transition-colors duration-300">
                      <TwitterIcon className="w-8 h-8" />
                    </a>
                  </div>
                </div>
                <div className="md:w-1/2 mt-8 md:mt-0">
                  <div className="aspect-w-16 aspect-h-9 h-full rounded-lg overflow-hidden shadow-md">
                    <iframe 
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3395.592234877856!2d-8.041760823760123!3d31.6723739396154!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xdafeb2c4be220a5%3A0x30f3cb66f80c3405!2sSpark%20Tech%20by%20Aitela!5e0!3m2!1sen!2sma!4v1727711148036!5m2!1sen!2sma" 
                      width="100%" 
                      height="100%" 
                      style={{ border: 0 }} 
                      allowFullScreen="" 
                      loading="lazy"
                    ></iframe>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
          
          <Card className="border-none" dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
            <CardContent className="p-6">
              <h3 className="text-2xl font-semibold mb-6">{t('contact_msg_title')}</h3>
              <p className="text-gray-600 mb-6">{t('contact_msg_desc')}</p>
              <form className="space-y-4">
                <div className='flex gap-4'>
                  <Input  id="first_name" type="text" placeholder={t('first_name')} className={`w-full ${errors.firstName ? 'border-red-500' : ''}`} value={form.firstName} onChange={(e) => setForm({ ...form, firstName: e.target.value })} />
                  <Input id="last_name" type="text" placeholder={t('last_name')} className={`w-full ${errors.lastName ? 'border-red-500' : ''}`} value={form.lastName} onChange={(e) => setForm({ ...form, lastName: e.target.value })} />
                </div>
                <div>
                  <Input id="number" type="tele" placeholder={t('phone')} className={`w-full ${errors.phone_number ? 'border-red-500' : ''}`} value={form.phone_number} onChange={(e) => setForm({ ...form, phone_number: e.target.value })} />
                </div>
                <div>
                  <Input id="email" type="email" placeholder={t('email')} className={`w-full ${errors.email ? 'border-red-500' : ''}`} value={form.email} onChange={(e) => setForm({ ...form, email: e.target.value })} />
                </div>
                <div>
                  <Textarea id="message" placeholder={t('your_message')} rows={4} className={`w-full ${errors.message ? 'border-red-500' : ''}`} value={form.message} onChange={(e) => setForm({ ...form, message: e.target.value })} />
                </div>
                <Button onClick={sendContactForm} type="button" className="w-full text-white">
                {t('send_msg')}
                </Button>
              </form>
            </CardContent>
          </Card>
        </div>
        
        
      </div>
      <div className="flex flex-row justify-between items-center text-center text-gray-600 mt-10 mx-20">
          <p>&copy; {t('all_rights')}</p>
          <div className="mt-4 flex flex-row gap-6">
              <a href="#" className="text-gray-600 hover:text-amber-600 transition-colors duration-300">{t('use_conds')}</a>
              <a href="#" className="block text-gray-600 hover:text-amber-600 transition-colors duration-300">{t('Pol_conf')}</a>
          </div>
      </div>
    </section>
  )
}