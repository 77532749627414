import axios from 'axios'

const server = axios.create({
    baseURL: 'https://spark-service-4762-47aec6dc-jkdfw6ig.onporter.run/api',
    headers: {
        'Content-Type': 'application/json',
    },
})

export default server
