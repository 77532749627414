import React from 'react'
import { Button } from "./UI/button.jsx"
import { useTranslation } from 'react-i18next';

export default function Header() {
  const { t, i18n } = useTranslation();
  const openUrl = () => {
    window.open('https://app.avocatspark.com/auth/register', '_blank');
  }
  return (
    <header className="flex items-center justify-between px-16 py-2 bg-white">
      <div className="flex items-center">
        <img src="Avocat-logo-transparent.png" alt="Avocat Spark Logo" className="h-20 w-40 mr-2" />
      </div>
      <nav className="hidden md:flex space-x-4">
        <a href="#features" className="text-gray-600 hover:text-gray-900 font-semibold">{t('features')}</a>
        <a href="#tarifs" className="text-gray-600 hover:text-gray-900">{t('tarifs')}</a>
        <a href="#faq" className="text-gray-600 hover:text-gray-900">{t('FAQ')}</a>
        <a href="#contact" className="text-gray-600 hover:text-gray-900">{t('Contact')}</a>
        <a href="#" className="text-gray-600 hover:text-gray-900" onClick={()=>i18n.changeLanguage(i18n.language == 'ar' ? 'fr' : 'ar')}>{t('language')}</a>
      </nav>
      <Button onClick={openUrl} className="bg-amber-700 text-white">{t('Register')}</Button>
    </header>
  )
}