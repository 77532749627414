import React from 'react'
import { BriefcaseIcon, CalendarIcon, UsersIcon, FileTextIcon, BarChartIcon, MessageSquareIcon } from "lucide-react"
import { useTranslation } from 'react-i18next';

function FeatureCard({ icon, title, description }) {
  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <div className="text-amber-600 mb-4">{icon}</div>
      <h3 className="text-xl font-semibold mb-2">{title}</h3>
      <p className="text-gray-600">{description}</p>
    </div>
  )
}

export default function Features() {
    const { t, i18n } = useTranslation();
  return (
    <section id="features" className="py-12 px-24">
      <h2 className="text-3xl font-bold text-center mb-8">{t('feature_title')}</h2>
      <div className="grid md:grid-cols-3 gap-16">
        <FeatureCard icon={<BriefcaseIcon />} title={t('feature_card_title_1')} description={t('feature_card_desc_1')} />
        <FeatureCard icon={<FileTextIcon />} title={t('feature_card_title_2')} description={t('feature_card_desc_2')} />
        <FeatureCard icon={<MessageSquareIcon />} title={t('feature_card_title_3')} description={t('feature_card_desc_3')} />
        <FeatureCard icon={<CalendarIcon />} title={t('feature_card_title_4')} description={t('feature_card_desc_4')} />
        <FeatureCard icon={<BarChartIcon />} title={t('feature_card_title_5')} description={t('feature_card_desc_5')} />
        <FeatureCard icon={<UsersIcon />} title={t('feature_card_title_6')} description={t('feature_card_desc_6')} />
      </div>
    </section>
  )
}