import React from 'react'
import { Button } from "./UI/button.jsx"
import { CheckIcon, XIcon } from "lucide-react"
import { useTranslation } from 'react-i18next';

export default function Pricing() {
    const { t, i18n } = useTranslation();
    const openUrl = () => {
      window.open('https://app.avocatspark.com/auth/register', '_blank');
    }
  return (
    <section id="tarifs" className={`py-12 px-4 bg-gray-100 ${i18n.language === 'ar' ? 'text-align-right' : ''}`}>
      <h2 className="text-3xl font-bold text-center mb-8">{t('offers_title')}</h2>
      <p className="text-center mb-8 text-xl">{t('offers_desc')}</p>
      <div className="max-w-6xl mx-auto overflow-x-auto">
        <table dir={`${i18n.language === 'ar' ? 'rtl' : ''}`} className="w-full rounded-lg">
          <thead>
            <tr className="bg-transparent">
              <th className="p-4 text-center">{t('Caracteristiques')}</th>
              <th className="p-4 text-center text-md"><p className='text-2xl'>{t('free')}</p><p className="py-2 text-center font-bold text-amber-700">{t('free_month')}</p><p className='text-gray-400'>{t('free_year')}</p></th>
              <th className="p-4 text-center"><p className='text-2xl'>{t('pro')}</p><p className="py-2 text-center font-bold text-amber-700">{t('pro_month')}</p><p className='text-gray-400'>{t('pro_year')}</p></th>
              <th className="p-4 text-center"><p className='text-2xl'>{t('business')}</p><p className="py-2 text-center font-bold text-amber-700">{t('business_month')}</p><p className='text-gray-400'>{t('free')}</p></th>
            </tr>
          </thead>
          <tbody className='bg-white shadow-lg font-semibold '>
            <tr>
              <td className="p-4">{t('num_users')}</td>
              <td className="p-4 text-center">1</td>
              <td className="p-4 text-center">5</td>
              <td className="p-4 text-center">{t('unlimited')}</td>
            </tr>
            <tr className="bg-gray-50">
              <td className="p-4">{t('clients')}</td>
              <td className="p-4 text-center">20</td>
              <td className="p-4 text-center">{t('unlimited')}</td>
              <td className="p-4 text-center">{t('unlimited')}</td>
            </tr>
            <tr>
              <td className="p-4">{t('cases')}</td>
              <td className="p-4 text-center">20</td>
              <td className="p-4 text-center">{t('unlimited')}</td>
              <td className="p-4 text-center">{t('unlimited')}</td>
            </tr>
            <tr className="bg-gray-50">
              <td className="p-4">{t('audiances')}</td>
              <td className="p-4 text-center">10</td>
              <td className="p-4 text-center">{t('20_month')}</td>
              <td className="p-4 text-center">{t('unlimited')}</td>
            </tr>
            <tr>
              <td className="p-4">{t('appointments')}</td>
              <td className="p-4 text-center">10</td>
              <td className="p-4 text-center">{t('50_month')}</td>
              <td className="p-4 text-center">{t('unlimited')}</td>
            </tr>
            <tr className="bg-gray-50">
              <td className="p-4">{t('documents')}</td>
              <td className="p-4 text-center">{t('1_case')}</td>
              <td className="p-4 text-center">{t('unlimited')}</td>
              <td className="p-4 text-center">{t('unlimited')}</td>
            </tr>
            <tr>
              <td className="p-4">{t('tasks')}</td>
              <td className="p-4 text-center"><XIcon className="mx-auto text-red-500" /></td>
              <td className="p-4 text-center">{t('unlimited')}</td>
              <td className="p-4 text-center">{t('unlimited')}</td>
            </tr>
            <tr className="bg-gray-50">
              <td className="p-4">{t('invoices')}</td>
              <td className="p-4 text-center"><XIcon className="mx-auto text-red-500" /></td>
              <td className="p-4 text-center"><CheckIcon className="mx-auto text-green-500" /></td>
              <td className="p-4 text-center"><CheckIcon className="mx-auto text-green-500" /></td>
            </tr>
            <tr>
              <td className="p-4">{t('reports')}</td>
              <td className="p-4 text-center"><XIcon className="mx-auto text-red-500" /></td>
              <td className="p-4 text-center"><CheckIcon className="mx-auto text-green-500" /></td>
              <td className="p-4 text-center"><CheckIcon className="mx-auto text-green-500" /></td>
            </tr>
            <tr className="bg-gray-50">
              <td className="p-4">{t('notifications')}</td>
              <td className="p-4 text-center"><XIcon className="mx-auto text-red-500" /></td>
              <td className="p-4 text-center"><CheckIcon className="mx-auto text-green-500" /></td>
              <td className="p-4 text-center"><CheckIcon className="mx-auto text-green-500" /></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="mt-8 text-center">
        <Button onClick={openUrl} className=" text-white">{t('offers_btn')}</Button>
      </div>
    </section>
  )
}